import { SEP_WORD, WORDS } from '../constants/wordlist'
import { alpha_2_kr, getStatuses } from './statuses'
import { CELL_COUNT } from '../constants/WordLength'

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(word)
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getRandomWord = () => {
  var solutionLength = WORDS.indexOf(SEP_WORD)
  if (solutionLength < 0) solutionLength = WORDS.length
  var index = Math.floor(solutionLength * Math.random())
  if (solution === WORDS[index]) {
    index = Math.floor(solutionLength * Math.random())
  }
  return WORDS[index]
}

export const getSmartRandomWord = (guesses: string[]) => {
  var greenPos: string[] = []
  guesses.forEach(guess => {
    for (var i = 0; i < CELL_COUNT; i += 1) {
      if (guess.charAt(i) === solution.charAt(i)) {
        greenPos[i] = guess.charAt(i)
      }
    }
  })

  const charStatuses = getStatuses(guesses)
  var remainWords = WORDS.filter(word => {  
    for (var item in charStatuses) {
      if (charStatuses[item] === 'absent') {
        if (word.indexOf(alpha_2_kr[item]) >= 0) {
          return false
        }
      }
    }
    return true
  }).filter(word => {
    for (var i = 0; i < CELL_COUNT; i += 1) {
      if (typeof greenPos[i] === 'string') {
        if (word.charAt(i) !== greenPos[i]) {
          return false
        }
      }
    }
    return true
  }).filter(word => {
    for (var i = 0; i < guesses.length; i += 1) {
      if (word === guesses[i]) {
        return false
      }
    }
    return word !== solution
  })

  var easyIndex = WORDS.indexOf(SEP_WORD)
  if (easyIndex > 0) {
    var easyWords = WORDS.slice(0, easyIndex)
    var easyRemainWords = remainWords.filter(item => {
      return easyWords.includes(item)
    })
    var easySolutionLength = easyRemainWords.length
    if (easySolutionLength > 0) {
      var easyRndIndex = Math.floor(easySolutionLength * Math.random())
      return easyRemainWords[easyRndIndex]
    }
  }

  var solutionLength = remainWords.length
  if (solutionLength > 0) {
    var rndIndex = Math.floor(solutionLength * Math.random())
    return remainWords[rndIndex]
  }

  return solution
}

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  // const epochMs = 1643113978753
  // const now = Date.now()
  // const msInDay = 86400000 / 2
  //const index = Math.floor((now - epochMs) / msInDay)
  //const index = Math.floor(WORDS.length * Math.random())

  var solutionLength = WORDS.indexOf(SEP_WORD)
  if (solutionLength < 0) solutionLength = WORDS.length
  var index = Math.floor(solutionLength * Math.random())
  //select index
  const hrefArr = document.location.href.split('?');
  const matched = hrefArr.length === 2 ? hrefArr[1].match(/no=(\d+)/) : null;
  if (matched && matched[1] && +matched[1] >= 0 && +matched[1] < solutionLength) {
    index = +matched[1];
  }

  return {
    solution: WORDS[index],
    solutionIndex: index,
    solutionLength: solutionLength
  }
}

export const { solution, solutionIndex, solutionLength } = getWordOfDay()
