import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import { CELL_COUNT } from '../constants/WordLength'


export const shareByClipboard = (guesses: string[]) => {
  try {
    navigator.clipboard.writeText(
      '#협동워들 #한글' + CELL_COUNT + '자 #무한워들 #워들 https://bw' + CELL_COUNT + '.azki.org No.' +
        solutionIndex +
        '\n\n' +
        generateEmojiGrid(guesses)
    )
    return 'clipboard'
  } catch (e) {
    return 'fail'
  }
}

export const shareByApi = (guesses: string[]) => {
  if (typeof navigator.share != 'undefined') {
    navigator.share({
      text: '#협동워들 #한글' + CELL_COUNT + '자 #무한워들 #워들 https://bw' + CELL_COUNT + '.azki.org No.' +
      solutionIndex +
      '\n\n' +
      generateEmojiGrid(guesses)
    })
    return 'api'
  }
  return 'fail'
}

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((letter, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩'
            case 'present':
              return '🟨'
            default:
              return '⬜'
          }
        })
        .join('')
    })
    .join('\n')
}
