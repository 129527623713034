import { InformationCircleIcon } from '@heroicons/react/outline'
import { ChartBarIcon } from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { WinModal } from './components/modals/WinModal'
import { LoseModal } from './components/modals/LoseModal'
import { StatsModal } from './components/modals/StatsModal'
import { isWordInWordList, isWinningWord, solution, solutionIndex, solutionLength, getRandomWord, getSmartRandomWord } from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'
import { CELL_COUNT } from './constants/WordLength'
import useInterval from './lib/useInterval'

function App() {
  const STYLE_DISPLAY_BLOCK = { display: 'block' }
  const STYLE_DISPLAY_NONE = { display: 'none' }

  const [enemyGuess, setEnemyGuess] = useState(getRandomWord())
  const [currentEnemyGuess, setCurrentEnemyGuess] = useState('')
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isWinModalOpen, setIsWinModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [isGameLostModalOpen, setIsGameLostModalOpen] = useState(false)
  const [shareClipboardComplete, setShareClipboardComplete] = useState(false)
  const [shareFailComplete, setShareFailComplete] = useState(false)
  const [waitForEnemy, setWaitForEnemy] = useState(false)
  const [aboutShow, setAboutShow] = useState(STYLE_DISPLAY_BLOCK)
  const [endShow, setEndShow] = useState(STYLE_DISPLAY_NONE)
  
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameLost(true)
      setIsGameLostModalOpen(true)
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useInterval(() => {
    if (enemyGuess.length > 0 && currentEnemyGuess !== enemyGuess) {
      if (currentEnemyGuess.length === 0 && !waitForEnemy) {
        setWaitForEnemy(true)
        setTimeout(() => {
          setWaitForEnemy(false)
        }, 2000)
      } else if ((currentEnemyGuess.length > 0 || guesses.length === 0 || Math.random() < 0.18) 
      && Math.random() < (0.1 * (currentEnemyGuess.length / enemyGuess.length * 4 + 1))) {
        setCurrentEnemyGuess(enemyGuess.substring(0, currentEnemyGuess.length + 1))
      }
    }
  }, 100);
  
  useEffect(() => {
    if (enemyGuess.length > 0) {
      if (currentEnemyGuess === enemyGuess) {
        const winningWord = isWinningWord(enemyGuess)
        if (enemyGuess.length === CELL_COUNT && guesses.length < 6 && !isGameWon) {
          setGuesses([...guesses, enemyGuess])
          setEnemyGuess('')
          setCurrentEnemyGuess('')
          setCurrentGuess('')
          if (winningWord) {
            setStats(addStatsForCompletedGame(stats, guesses.length))
            setIsGameWon(true)
          } else if (guesses.length === 5) {
            setStats(addStatsForCompletedGame(stats, guesses.length + 1))
            setIsGameLost(true)
            setIsGameLostModalOpen(true)
          }
        }
      }
    }
  }, [currentEnemyGuess, enemyGuess, isGameWon, stats, guesses, currentGuess])

  useEffect(() => {
    if (enemyGuess.length > 0 && currentEnemyGuess.length < CELL_COUNT && guesses.length < 6 && !isGameWon) {
      setCurrentGuess(`${currentEnemyGuess}`)
    }
  }, [enemyGuess, currentEnemyGuess, isGameWon, guesses])

  useEffect(() => {
    if (isGameWon) {
      setIsWinModalOpen(true)
    }
  }, [isGameWon])

  const onChar = (value: string) => {
    if (enemyGuess.length === 0 && currentGuess.length < CELL_COUNT && guesses.length < 6 && !isGameWon) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    if (enemyGuess.length === 0) {
      setCurrentGuess(currentGuess.slice(0, -1))
    }
  }

  const onEnter = () => {
    if (enemyGuess.length === 0 && !(currentGuess.length === CELL_COUNT) && !isGameLost) {
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, 2000)
    }

    if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, 2000)
    }

    const winningWord = isWinningWord(currentGuess)
    if (currentGuess.length === CELL_COUNT && guesses.length < 6 && !isGameWon) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')
      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        setIsGameWon(true)
      } else if (guesses.length === 5) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsGameLost(true)
        setIsGameLostModalOpen(true)
      } else {
        setEnemyGuess(getSmartRandomWord([...guesses, currentGuess]))
      }
    }
  }

  return (
    <div className="py-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center pb-6">
        <h1 className="text-xl grow font-bold">협동워들 {CELL_COUNT}자 ({solutionIndex}/{solutionLength})</h1>
        <InformationCircleIcon
          className="h-6 w-6 cursor-pointer"
          style={{margin:'0 0.5rem'}}
          onClick={() => setIsInfoModalOpen(true)}
        />
        <ChartBarIcon
          className="h-6 w-6 cursor-pointer"
          onClick={() => setIsStatsModalOpen(true)}
        />
      </div>
      <Grid guesses={guesses} currentGuess={currentGuess} />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
      />
      <WinModal
        isOpen={isWinModalOpen}
        handleClose={() => {
          setIsWinModalOpen(false);
          setAboutShow(STYLE_DISPLAY_NONE)
          setEndShow(STYLE_DISPLAY_BLOCK)
        }}
        guesses={guesses}
        handleShare={(shareResult) => {
          setAboutShow(STYLE_DISPLAY_NONE)
          setEndShow(STYLE_DISPLAY_BLOCK)
          if (shareResult === 'clipboard') {
            setShareClipboardComplete(true)
            return setTimeout(() => {
              setShareClipboardComplete(false)
            }, 2000)
          } else if (shareResult === 'fail') {
            setShareFailComplete(true)
            return setTimeout(() => {
              setShareFailComplete(false)
            }, 2000)
          }
        }}
        solution={solution}
      />
      <LoseModal
        isOpen={isGameLostModalOpen}
        handleClose={() => {
          setIsGameLostModalOpen(false)
          setAboutShow(STYLE_DISPLAY_NONE)
          setEndShow(STYLE_DISPLAY_BLOCK)
        }}
        guesses={guesses}
        solution={solution}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        gameStats={stats}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />
      
      <button
        id="about_btn"
        style={aboutShow}
        type="button"
        className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        onClick={() => setIsAboutModalOpen(true)}
      >
        비슷한 다른 게임 <span id="solution_debug">{
        //solution
        }</span>
      </button>

      <button
        id="regame_btn"
        style={endShow}
        type="button"
        className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        onClick={ () => {
          if (isGameWon) setIsWinModalOpen(true);
          else document.location.reload();
        }}
      >
        {isGameWon ? '게임 승리' : '새로운 게임'}
      </button>

      <Alert message="글자를 더 입력해 주세요" isOpen={isNotEnoughLetters} />
      <Alert message="등재되지 않은 단어입니다" isOpen={isWordNotFoundAlertOpen} />
      <Alert
        message="결과가 클립보드에 복사되었습니다."
        isOpen={shareClipboardComplete}
        variant="success"
      />
      <Alert
        message="공유가 실패했어요. (지원 불가)"
        isOpen={shareFailComplete}
        variant="warning"
      />
      <Alert
        message="상대방 입력중.."
        isOpen={waitForEnemy}
        variant="waiting"
      />
    </div>
  )
}

export default App
