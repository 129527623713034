import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <div className="text-sm text-gray-500">
        이 게임은 한글 Wordle를 하루종일 하고 싶은 분들을 위한 변형판입니다<br/>
        <p> - <a href="https://w5.azki.org"
          className="underline font-bold">
          한글5자는 여기에서
        </a> - </p>
        <p> - <a href="https://w6.azki.org"
          className="underline font-bold">
          한글6자는 여기에서
        </a> - </p>
        <p> - <a href="https://w7.azki.org"
          className="underline font-bold">
          한글7자는 여기에서
        </a> - </p>
        <p> - <a href="https://bw5.azki.org"
          className="underline font-bold">
          (new) 협동워들 5자
        </a> - </p>
        <p> - <a href="https://bw6.azki.org"
          className="underline font-bold">
          (new) 협동워들 6자
        </a> - </p>
        <br/>
        이 게임을 차르디님께 바칩니다.
        <br/><br/>
        <a href="https://www.powerlanguage.co.uk/wordle/" target={'_blank'} rel="noreferrer noopener"
          className="underline font-bold">
          오리지널은 여기에서
        </a>
      </div>
    </BaseModal>
  )
}
