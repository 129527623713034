import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { CELL_COUNT } from '../../constants/WordLength'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  if (CELL_COUNT > 6) {
    return (
      <BaseModal title="게임 방법" isOpen={isOpen} handleClose={handleClose}>
        <p className="text-sm text-gray-500">
        워들을 맞춰봅시다. 각 기회마다 글자 조각의 색깔이 문제를 맞추는데 도움을 줍니다.
        </p>
  
        <div className="flex justify-center mb-1 mt-4">
          <Cell value="ㅈ" status="correct" />
          <Cell value="ㅏ" />
          <Cell value="ㅇ" />
          <Cell value="ㄱ" />
          <Cell value="ㅗ" />
          <Cell value="ㅏ" />
          <Cell value="ㄴ" />
        </div>
        <p className="text-sm text-gray-500">
        글자 'ㅈ'은 올바른 자리에 있습니다.
        </p>
  
        <div className="flex justify-center mb-1 mt-4">
          <Cell value="ㅈ" />
          <Cell value="ㅣ" />
          <Cell value="ㄹ" status="present" />
          <Cell value="ㅣ" />
          <Cell value="ㅅ" />
          <Cell value="ㅏ" />
          <Cell value="ㄴ" />
        </div>
        <p className="text-sm text-gray-500">
        글자 'ㄹ'은 잘못된 자리에 있습니다.
        </p>
  
        <div className="flex justify-center mb-1 mt-4">
          <Cell value="ㅇ" />
          <Cell value="ㅣ" />
          <Cell value="ㅇ" />
          <Cell value="ㅓ" status="absent" />
          <Cell value="ㅍ" />
          <Cell value="ㅗ" />
          <Cell value="ㄴ" />
        </div>
        <p className="text-sm text-gray-500">
        글자 'ㅓ'는 어느 곳에도 맞지 않습니다.
        </p>
      </BaseModal>
    )
  } else if (CELL_COUNT > 5) {
  return (
    <BaseModal title="게임 방법" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500">
      워들을 맞춰봅시다. 각 기회마다 글자 조각의 색깔이 문제를 맞추는데 도움을 줍니다.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="ㄱ" status="correct" />
        <Cell value="ㅗ" />
        <Cell value="ㅅ" />
        <Cell value="ㅏ" />
        <Cell value="ㄹ" />
        <Cell value="ㅣ" />
      </div>
      <p className="text-sm text-gray-500">
      글자 'ㄱ'은 올바른 자리에 있습니다.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="ㅁ" />
        <Cell value="ㅕ" />
        <Cell value="ㄴ" status="present" />
        <Cell value="ㅈ" />
        <Cell value="ㅓ" />
        <Cell value="ㅂ" />
      </div>
      <p className="text-sm text-gray-500">
      글자 'ㄴ'은 잘못된 자리에 있습니다.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="ㅇ" />
        <Cell value="ㅡ" />
        <Cell value="ㅣ" />
        <Cell value="ㅅ" />
        <Cell value="ㅜ" status="absent" />
        <Cell value="ㄹ" />
      </div>
      <p className="text-sm text-gray-500">
      글자 'ㅜ'은 어느 곳에도 맞지 않습니다.
      </p>
    </BaseModal>
  )
  } else {
    return (
      <BaseModal title="게임 방법" isOpen={isOpen} handleClose={handleClose}>
        <p className="text-sm text-gray-500">
        워들을 맞춰봅시다. 각 기회마다 글자 조각의 색깔이 문제를 맞추는데 도움을 줍니다.
        </p>
  
        <div className="flex justify-center mb-1 mt-4">
          <Cell value="ㅈ" status="correct" />
          <Cell value="ㅏ" />
          <Cell value="ㅁ" />
          <Cell value="ㅗ" />
          <Cell value="ㅇ" />
        </div>
        <p className="text-sm text-gray-500">
        글자 'ㅈ'은 올바른 자리에 있습니다.
        </p>
  
        <div className="flex justify-center mb-1 mt-4">
          <Cell value="ㅂ" />
          <Cell value="ㅓ" />
          <Cell value="ㄴ" status="present" />
          <Cell value="ㄱ" />
          <Cell value="ㅐ" />
        </div>
        <p className="text-sm text-gray-500">
        글자 'ㄴ'은 잘못된 자리에 있습니다.
        </p>
  
        <div className="flex justify-center mb-1 mt-4">
          <Cell value="ㅂ" />
          <Cell value="ㅗ" />
          <Cell value="ㄹ" />
          <Cell value="ㅡ" status="absent" />
          <Cell value="ㅁ" />
        </div>
        <p className="text-sm text-gray-500">
        글자 'ㅡ'은 어느 곳에도 맞지 않습니다.
        </p>
      </BaseModal>
    )  
  }
}
